import { currencyToNumber } from '@/@core/helpers/formatter';
import { computed, unref } from '@vue/composition-api';

export default function useCalculateAllCosts(
  calculateTotal: any,
  costs: any
) {
  const {
    rawMaterialPerMonth,
    additivesMaterialPerMonth,
    auxiliaryMaterialPerMonth,
    operationalPerMonth,
    otherCostPerMonth,
    wagePerMonth
  } = unref(calculateTotal);

  // const { operationalPerMonth, laborWagesPerMonth, othersPerMonth } =
  //   unref(costs);

  const hasAllData =
    rawMaterialPerMonth &&
    additivesMaterialPerMonth &&
    auxiliaryMaterialPerMonth &&
    operationalPerMonth &&
    wagePerMonth;

  const total =
    (currencyToNumber(rawMaterialPerMonth) || 0) +
    (currencyToNumber(additivesMaterialPerMonth) || 0) +
    (currencyToNumber(auxiliaryMaterialPerMonth) || 0) +
    (currencyToNumber(operationalPerMonth) || 0) +
    (currencyToNumber(otherCostPerMonth) || 0) +
    (currencyToNumber(wagePerMonth) || 0);
  // (currencyToNumber(laborWagesPerMonth) || 0) +
  // (currencyToNumber(othersPerMonth) || 0);

  return total;
}

export { useCalculateAllCosts };
