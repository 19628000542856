






































































import KTextInput from '@/@core/components/input/KTextInput.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import useModelValue from '@/@core/helpers/modelValue';
import {
  computed,
  defineComponent,
  onMounted,
  PropType
} from '@vue/composition-api';
import { useCalculateAllCosts } from '../services/calculateAllCosts';
import {
  currencyToNumber,
  toCurrency,
  useNumberToCurrency
} from '@/@core/helpers/formatter';
// import KCurrencyInput from '@/@core/components/input/KCurrencyInput.vue';
import KCurrencyInput from '@/@core/components/input/k-currency-input.vue';

export default defineComponent({
  components: { KTextInput, KText, KCard, KCurrencyInput },
  name: 'CostsForm',
  props: {
    reRenderCount: {
      type: Number as PropType<number>,
      required: true
    }
  },
  setup(props, { emit, attrs }) {
    const modelValue = useModelValue({ emit, attrs });

    const materialKey = {
      'raw-materials': 0,
      'additives-materials': 1,
      'auxiliary-materials': 2
    };
    const calculateMaterial = (material: number, key: string) => {
      let total: number = 0;

      // @ts-ignore
      const materials: any[] = modelValue.value.materials || [];
      if (!materials.length) return total;
      materials[material].data.map(
        (d: any) => (total += currencyToNumber(d[key]) || 0)
      );

      return toCurrency(total);
    };

    const calculateOperational = (
      keyMaterial: string,
      keyToCount: string
    ) => {
      let total: number = 0;

      // const operationals: any[] = modelValue.value.operationalCosts;
      const operationals: any[] = modelValue.value[keyMaterial];
      if (!operationals?.length) return total;

      operationals.map(
        (o) => (total += currencyToNumber(o[keyToCount]) || 0)
      );

      return toCurrency(total);
    };

    const calculateTotal = computed(() => {
      return {
        rawMaterialPerMonth: calculateMaterial(
          materialKey['raw-materials'],
          'totalNeedsPerMonth'
        ),
        additivesMaterialPerMonth: calculateMaterial(
          materialKey['additives-materials'],
          'totalNeedsPerMonth'
        ),
        auxiliaryMaterialPerMonth: calculateMaterial(
          materialKey['auxiliary-materials'],
          'totalNeedsPerMonth'
        ),
        operationalPerMonth: calculateOperational(
          'operationalCosts',
          'totalNeedsPerMonth'
        ),
        otherCostPerMonth: calculateOperational(
          'otherCosts',
          'totalNeedsPerMonth'
        ),
        wagePerMonth: calculateOperational(
          'wages',
          'totalNeedsPerMonth'
        )
      };
    });

    const allCosts = computed(() => {
      const total = useCalculateAllCosts(
        calculateTotal,
        modelValue.value.costs
      );
      modelValue.value.totalCostsPerMonth = total;

      return total;
    });

    onMounted(() => {
      const hasCosts = modelValue.value?.costs;

      if (!hasCosts) modelValue.value.costs = {};
    });

    return {
      calculateTotal,
      modelValue,
      allCosts,
      useNumberToCurrency
    };
  }
});
